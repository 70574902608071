import { useState } from 'react'
import styles from 'styles/DisplayOnClick.module.css'
import btnStyles from '@components/salon/SalonButtons.module.css'
import useT from '@hooks/useTranslation'

const DisplayOnClick = ({ children, text = '', icon = '', callback }) => {
    const [view, setView] = useState(false)
    const show = <span className={btnStyles.btn} style={{fontWeight:400}}>{useT('Pokaż numer')}</span>
    return view
        ? children
        : <div className={styles.placeholder}
            onClick={() => {
                if (callback) callback()
                setView(true)
            }}>

            {icon} {text.slice(0, 3)}... {show}</div>
}

export default DisplayOnClick