function sortUrlParams (url, exclude = []) {
    const [base,search] = url.split('?')
    if (!search) return url
    const paramFilter = notIn(exclude, base)
    const sortedSearch = search.split('&').sort().filter(paramFilter).join('&')
    return sortedSearch
        ? `${base}?${sortedSearch}`
        : base
}

function notIn (exclude, baseUrl) {
    return param => exclude.every(omit => {
        if (param.startsWith(omit)) return false
        const value = valueFrom(param)
        if (value && baseUrl.includes(value)) return false
        return true
    })
}

const paramsThatShowInUrl = {
    'marka': true,
    'model': true,
    'lokalizacja': true,
}

function valueFrom (param) {
    if (!param) return ''
    const [key, value] = param.split('=')
    if (!paramsThatShowInUrl[key]) return ''
    return value
}

module.exports = sortUrlParams