import Triptic from '@components/display/Triptic'
import MobileImage from '@components/image/Mobile100Width'
import Photo from '@components/display/photos/SinglePhoto'
import UserDetails from '@components/display/user/Details'
import UserLocation from '@components/display/user/Location'
import withBreakpoints from '@hoc/withBreakpoints'

const fallbackImage = '/fallback/lead-salon-samochodowy.png'

function SalonHead ({
    breakpoints: { mobile },
    salon,
    noLink,
    salon: { _id, dealer: { firm_name }},
    visitor,
}) {

    if (noLink) salon.noLink = true

    const src = `/api/photos/salon_photo/${_id}`

    return (
        <div style={{ borderTop: '1px solid var(--input-border-c)' }}>
            <Triptic>
                {
                    mobile
                        ? <MobileImage alt={firm_name} fallback={fallbackImage} src={src} />

                        : <div style={{ minHeight: 400 }}><Photo
                            alt={firm_name}
                            fallback={fallbackImage}
                            src={src}
                          /></div>
                }
                <UserDetails user={salon} visitor={visitor} nocontact salon />
                <UserLocation user={salon} salon />
            </Triptic>
        </div>
    )
}

export default withBreakpoints({ mobile: 1024 })(SalonHead)