import AdvancedImage from '@components/image/WithFallback'
import ImageWrapper from '@components/image/WrapperFilling'

const Photo = ({ alt, src, fallback, fit = 'cover', layout = 'fill' }) => {
    return (
        <ImageWrapper>
            <AdvancedImage
                src={src}
                fallback={fallback}
                alt={alt || ''}
                layout={layout}
                objectFit={fit} />
        </ImageWrapper>
    )
}

export default Photo