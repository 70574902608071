import Head from 'next/head'
import { useRouter } from 'next/router'
import sortUrlParams from 'src/seo/server/sortUrlParams'
import DEFAULTS from 'src/seo/constants/defaults'
import getQuerySize from '@components/frontpage/functions/getQuerySize'

const HOST = process.env.NEXT_PUBLIC_HOST
const onStage = process.env.NEXT_PUBLIC_STAGE === 'true'

export default function Seo (props) {
    const router = useRouter()

    const seo = resolveSeo(props)
    const { canonicalExcludes = [], defaultValues = DEFAULTS, deletedOffer, offers, query } = props
    const { page } = seo

    const querySize = getQuerySize(query)

    const canonical = seo?.canonical || resolveCanonical(router, ['filtry', ...(canonicalExcludes || [])])
    const image = ensureHost(seo?.image || defaultValues.image) + '?og=1'

    const noOffers = offers && offers.notFound
    const withOffers = Array.isArray(offers) && offers.length > 0

    const indexAndFollow = withOffers || noOffers || page && page > 1
    const dontIndexAndFollow = deletedOffer // || canonical.endsWith('samochody-osobowe/uzywane'
    const dontIndexAndDontFollow = querySize > 3 || seo.robots == 'noindex, nofollow'

    if (dontIndexAndDontFollow) {
        seo.robots = 'noindex, nofollow'

    } else if (indexAndFollow) {
        seo.robots = 'index, follow'

    } else if (dontIndexAndFollow) {
        seo.robots = 'noindex, follow'
    }

    if (router.locale !== 'pl-PL') { // should override every other setting
        seo.robots = 'noindex, nofollow'
        seo.h2 = ' '
    }

    return <Head>
        { seo?.pageId && // for marking purposes
            <meta key="sPageId" name="S_PAGE_ID" content={seo.pageId} /> }

        <link key="canonical" rel="canonical" href={canonical} />
        <meta key="ogUrl" property="og:url" content={canonical} />

        <title>{seo?.title || defaultValues.title}</title>
        <meta
            key="description"
            name="description"
            content={seo?.description || defaultValues.description} />

        <meta
            key="ogTitle"
            property="og:title"
            content={seo?.ogTitle || seo?.title || defaultValues.ogTitle || defaultValues.title} />
        <meta
            key="ogDescription"
            property="og:description"
            content={seo?.ogDescription || seo?.description || defaultValues.ogDescription || defaultValues.description} />
        <meta
            key="ogImage"
            property="og:image"
            content={image} />
        <meta
            key="ogImageWidth"
            property="og:image:width"
            content={seo?.imageWidth || defaultValues.imageWidth} />
        <meta
            key="ogImageHeight"
            property="og:image:height"
            content={seo?.imageHeight || defaultValues.imageHeight} />

        <meta
            key="twitterImage"
            name="twitter:image"
            content={image} />
        <meta
            key="twitterTitle"
            name="twitter:title"
            content={seo?.twitterTitle || seo?.title || defaultValues.twitterTitle || defaultValues.title} />
        <meta
            key="twitterDescription"
            name="twitter:description"
            content={seo?.twitterDescription || seo?.description || defaultValues.twitterDescription || defaultValues.description} />

        { seo?.robots && <meta key="robots" name="robots" content={onStage ? 'noindex' : seo.robots} /> }
    </Head>
}

function resolveSeo ({ seo, seoPageDefaults }) {
    if (seoPageDefaults) {
        return { ...seoPageDefaults, ...nonFileProps(seo) }
    } else {
        return seo
    }
}

function nonFileProps (seo) {
    return Object
        .keys(seo)
        .reduce((acc, key) => {
            if (!key.endsWith('file')) acc[key] = seo[key]
            return acc
        }, {})
}

function ensureHost (text) {
    return text.startsWith('http')
        ? text
        : HOST + text
}

function resolveCanonical (router, canonicalExcludes) {
    const { locale, asPath } = router
    const params = sortUrlParams(asPath, canonicalExcludes)
    if (locale === 'pl-PL') return `${HOST}${params}`
    else return `${HOST}/${locale}${params}`
}