import Image from "next/legacy/image"
import Section from '@components/display/DisplaySectionSmall'
import DisplayOnClick from '@components/display/DisplayOnClick'
import isEmptyArray from '@utils/array/isEmptyArray'
import useT from '@hooks/useTranslation'
import useViewCallback from './useViewCallback'

import styles from 'styles/UserPhones.module.css'

const Phones = ({ user, offer }) => {
    const { contact, dealer } = user || {}

    const phones = getDealerPhones(dealer) || offer?.tracking?.phone || contact?.phone

    const viewCallback = useViewCallback(user, offer)

    return (
        <Section>
            <ListOutPhones phones={phones} viewCallback={viewCallback} />
        </Section>
    )
}

function ListOutPhones ({ phones, viewCallback }) {
    const niePodano = useT('Nie podano telefonu')
    if (!phones || isEmptyArray(phones)) {
        return <p>{niePodano}</p>
    }

    const phoneArray = ensureArray(phones)

    const icon = <Image
        src="/icons/telefon.webp"
        alt="Telefon do sprzedawcy"
        width="26"
        height="26" />

    return (
        <div className={styles.wrapper}>
            <div className={styles.phones}>
                { phoneArray.map(phone => (
                    <DisplayOnClick key={phone} text={phone} icon={icon} callback={viewCallback}>
                        <>
                            <Phone phone={phone} />
                            <br/>
                        </>
                    </DisplayOnClick>
                )) }
            </div>
        </div>
    )
}

function Phone ({ phone }) {
    return <a className={styles.phone} href={`tel:${phone}`}>{phone}</a>
}

function ensureArray (arrayToBe) {
    return Array.isArray(arrayToBe)
        ? arrayToBe
        : [arrayToBe]
}

function getDealerPhones (dealer) {
    if (typeof dealer !== 'object') return
    const { firm_phone, firm_phone_2, firm_phone_3 } = dealer
    const phones = [...ensureArray(firm_phone), firm_phone_2, firm_phone_3].filter(Boolean)
    if (!phones.length) return
    return phones
}

export default Phones