import Link from 'next/link'
import Logo from '@components/display/user/Logo'
import SalonSubscriptionOptions from '@components/salon/SubscriptionOptions'
import useT from '@hooks/useTranslation'
import salonLink from '@utils/links/salonLink'
import userName from '@utils/user/userName'

import styles from 'styles/UserNameCard.module.css'
import btnStyles from '../../salon/SalonButtons.module.css'

const NameCard = ({ user, noLink, visitor, salon }) => {
	const { _id, has_logo, dealer } = user || {}
	const { subdomain } = dealer || {}

	const display = userName(user) || useT('Brak danych')
	const linkText = useT('Zobacz pełną ofertę')

	const scrollToOffer =
		salon &&
		(() => {
			const h = document.getElementById('salon-list-heading')
			if (h) h.scrollIntoView({ behavior: 'smooth' })
		})

	return (
        <div className={styles.wrapper}>
			<div>
				{has_logo && (
					<span>
						<Logo
							_id={_id}
							alt={display}
							has_logo={has_logo}
							style={{
								width: 150,
								height: 150,
								margin: '16px auto',
								background: 'inherit',
								padding: 0,
							}}
						/>
					</span>
				)}

				{salon ? (
					<h1 className={styles.name}>{display}</h1>
				) : (
					<span className={styles.name}>{display}</span>
				)}
			</div>

			<SalonSubscriptionOptions user={visitor} salon={user} />

			{subdomain && !salon && !noLink && (
				(<Link
                    href={salonLink(subdomain)}
                    className={btnStyles.btn}
                    style={{ padding: 12, fontSize: 16 }}>

                    {linkText} {noLink}

                </Link>)
			)}

			{salon && (
				<a
					className={btnStyles.btn}
					role="button"
					style={{ padding: 12 }}
					onClick={scrollToOffer}
				>
					{linkText}
				</a>
			)}
			<br />
		</div>
    );
}

export default NameCard