import { useCallback, useState, useEffect } from 'react'
import Button from '@components/button/Button'
import useT from '@hooks/useTranslation'

const cache = {}

const useGradualFetch = (baseUrl, fetcher, limit = 15, initialData, shouldFetch = true, cacheKey = '') => {
    const [loading, setLoading] = useState(false)
    const [data, setData] = useState(initialData || cache[cacheKey] || [])
    const [done, setDone] = useState(false)

    const fetch = useCallback((prevData) => {
        if (loading || !shouldFetch) return

        setLoading(true)

        const url = baseUrl
            .replace(':skip', (prevData || data).length)
            .replace(':limit', limit)

        fetcher(url)
            .then(res => {
                setLoading(false)
                if (res.length < limit) setDone(true)
                const newData = (prevData || data).concat(res)
                if (cacheKey) cache[cacheKey] = newData
                setData(newData)
            })
            .catch(err => {
                setLoading(false)
                console.log(err)
            })

    }, [baseUrl, cacheKey, data, fetcher, limit, loading, shouldFetch])

    const refresh = useCallback(() => {
        setDone(false)
        fetch([])
    }, [fetch])

    useEffect(() => {
        const data = initialData || cache[cacheKey]
        if (!data?.length) refresh()
    }, [baseUrl])

    const button = (
        <div><br/><Button onClick={() => fetch()} blue>
            {useT('Wczytaj więcej')}</Button></div>
    )

    const hideButton = loading || done

    return [data, loading, hideButton
        ? null
        : button, refresh]
}

export default useGradualFetch