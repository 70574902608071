import SanitizedHTML from '@components/html/Sanitized'

export default function SalonDescription (
    { salon: { dealer: { html_description }}, ...rest }) {

    return html_description
        ? <div className="container" style={{ padding: '50px 0' }}>
            <SanitizedHTML html={ html_description } {...rest} />
          </div>

        : null
}