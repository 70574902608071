// import { useEffect, useRef, useState } from 'react'
import styles from 'styles/NavLinkColor.module.css'

const NavLinkButton = ({ onClick, color, children, ...rest }) => {
    // const timeoutIdxRef = useRef()
    // const [clickedRecently, setClickedRecently] = useState(false)
    // const _onClick = () => {
    //     clearTimeout(timeoutIdxRef.current)
    //     timeoutIdxRef.current = setTimeout(() => setClickedRecently(false), 1200)
    //     if (clickedRecently) return
    //     setClickedRecently(true)
    //     onClick()
    // }

    // useEffect(() => {
    //     return () => clearTimeout(timeoutIdxRef.current)
    // }, [])

    return <button
        className={`${styles.navlink} ${styles[color]}`}
        data-button="true"
        onClick={onClick}
        {...rest}
    >
        { children }
    </button>
}

export default NavLinkButton