import { TripticWrapper as Wrapper } from '@components/display/Triptic'
import Map from '@components/map/Map'
import Section from '@components/display/DisplaySectionSmall'

import styles from 'styles/UserLocation.module.css'

const Location = ({ user, offer, salon }) => {
    const { location, address, dealer } = user || {}
    const { location: offerLocation, tracking, rent } = offer || {}

    const { location: dealerLocation, firm_city } = dealer || {}

    const coordinates = rent?.reception_location?.coordinates
        || dealerLocation?.coordinates
        || offerLocation?.coordinates
        || location?.coordinates

    const city = firm_city
        || tracking?.city
        || rent?.reception_city
        || rent?.return_city
        || address?.city
        || dealer?.firm_address?.join(', ')
        || address?.full

    return (
        <Wrapper>
            <Map
                coordinates={coordinates}
                height='100%' />

            <Section gray>
                { city && salon
                    ? <h2 className={styles.city}>{city}</h2>
                    : <div className={styles.city}>{city}</div> }
            </Section>
        </Wrapper>
    )
}

export default Location